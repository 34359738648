<!--
 * @Description: 
 * @Author: wangying
 * @Date: 2021-10-09 09:43:06
-->
<template>
  <div>
    <div>
      <div class="title">
        <ListTitle :headerNmae="headerNmae" :headerNmaeCh="headerNmaeCh"></ListTitle>
      </div>
      <p class="hr"></p>
      <div class="fx">
        <div class="lt">
          <div class="paths">房地产行业绿色供应链行动秘书处</div>
          <div class="path">地址:</div>
          <div class="pot_1 ">北京市朝阳区来广营朝来高科技产业园创远路36号院3号楼4层</div> <!-- Impact -->
          <div class="path pot">电话:</div>
          <div class="pot_1 ">010-57505155-8016</div>
          <div class="path pot">邮编:</div>
          <div class="pot_1 ">100012</div>
        </div>
        <div class="rt pdrT">
          <img class="" src="@/assets/Qrcode_1.png" alt="">
          <p class="">扫码关注绿链行动官方微信公众号</p>
          <!-- <p class=""> 微信号: GSC20160605</p> -->
        </div>
      </div>
      <div class="mapks">
        <div id="container"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ListTitle from "@/components/reuse/listTitle";

export default {
  data() {
    return {
      headerNmaeCh: '<p style="color: #57802b;">Contact Us</p>',
      headerNmae:
        '<p style="color: #57802b; letter-spacing:2px; font-size: 25px;">联系我们</p>',
    }
  },
  mounted() {
    var map = new AMap.Map('container', {
      zoom: 16,//级别
      center: [116.469934, 40.019737],//中心点坐标
      viewMode: '3D',//使用3D视图
      mapStyle: 'amap://styles/whitesmoke',
    });
    var content = '<div style=" width: 50px;height: 50px; background: #E60012; border-radius: 50%;"></div>';
    var marker = new AMap.Marker({
      content: content,  // 自定义点标记覆盖物内容
      position: new AMap.LngLat(116.469934, 40.019737),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      title: '北京'
    });
    map.add(marker);
    this.hub.$emit('tatleNames')
    this.hub.$emit('theCover', this.$route.meta.flagNam)

    this.hub.$emit('scrollToz')
  },
  components: {
    ListTitle
  }
}
</script>
<style lang="less" scoped>
.fx {
  display: flex;

  margin: 50px 0 20px 0;
}
.title {
  margin-top: 30px;
}
.hr {
  margin-top: 34px;
  margin-right: 22px;
  margin-bottom: 10px;
  border-top: 2px solid #57802b;
}
.lt,
.rt {
  flex: 1;

  p {
    // text-align: right;
    color: #727171;
  }
}
.pdrT {
  // padding-right: 75px;
  text-align: center;

  position: absolute;
  right: 0;
  img {
    width: 258px;
    height: 258px;
  }
}
.paths {
  margin-top: -20px;
  margin-bottom: 10px;
  color: #727171;
  font-size: 20px;
  color: #57802b;
  font-size: 30px;
  font-weight: bold;
}
.pdr {
}
.lt {
  padding: 0 100px 0 0;
}
.path {
  font-size: 20px;
  font-weight: bold;
}
.path_1 {
  margin-top: 10px;

  color: #727171;
  font-size: 20px;
}
.pot {
  margin: 20px 0 0 0;
}
.pot_1 {
  color: #57802b;
  word-break: keep-all;
  white-space: nowrap;
  font-size: 30px;
  letter-spacing: 2px;
  color: #727171;
  font-size: 20px;

  // font-weight: bold;
}
.image {
  width: 170px;
  height: 170px;
  margin-top: 20px;
  margin-left: 240px;
}
.pdrT1 {
  text-align: center;
}
.mapks {
  margin: 80px 0;
  width: 1060px;
  height: 580px;
}
#container {
  width: 800px;
  height: 400px;
}
.marker-route {
}
</style>